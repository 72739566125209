import _ from 'lodash';

const innerJoin = (ob1, ob2, id1, id2 = id1) => {
  const join = [];
  _.forEach(ob1, (elt1) => {
    _.forEach(ob2, (elt2) => {
      if (elt2[id2] === elt1[id1]) {
        const el2 = _.clone(elt2);
        delete el2[id2];
        join.push(_.merge(elt1, el2));
      }
    });
  });
  return join;
};

export default innerJoin;
