const prefixes = [
  {
    prefix: 'giga', scale: 1000000000, scaleWord: "'billion'", abbr: 'G',
  },
  {
    prefix: 'mega', scale: 1000000, scaleWord: "'million'", abbr: 'M',
  },
  {
    prefix: 'kilo', scale: 1000, scaleWord: "'thousand'", abbr: 'k',
  },
  {
    prefix: '', scale: 1, scaleWord: "''", abbr: '',
  },
  {
    prefix: 'centi', scale: 0.01, scaleWord: "'hundredth'", abbr: 'c',
  },
  {
    prefix: 'milli', scale: 0.001, scaleWord: "'thousandth'", abbr: 'm',
  },
  {
    prefix: 'micro', scale: 0.000001, scaleWord: "'millionth'", abbr: '\u00b5',
  },
  {
    prefix: 'nano', scale: 0.000000001, scaleWord: "'billionth'", abbr: 'n',
  },
];

export default prefixes;
