import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import classes from './TipBoxBook.module.scss';
import StyleTipButton from './themed/StyleTipButton';
import Markdown from './Markdown';

const TipBoxBook = (props) => {
  let icon = null;
  if (props.type === 'tip') {
    icon = <FontAwesomeIcon icon={faLightbulb} className="fa-2x white" />;
  } else {
    icon = <FontAwesomeIcon icon={faExclamationTriangle} className="fa-2x white" />;
  }
  return (
    <div className={`d-flex w-100 ${classes.tip}`}>
      <StyleTipButton size="sm" className={classes.tipButton} acc="true" bg="true">
        {icon}
      </StyleTipButton>
      <div
        className={`d-flex w-100 text-left ${classes.tipBox}`}
        style={{ border: `solid 1.5px ${props.accent}` }}
      >
        <Markdown source={props.content} />
      </div>
    </div>
  );
};

TipBoxBook.propTypes = {
  accent: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  main: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  main: state.color.main,
  accent: state.color.accent,
});

export default connect(mapStateToProps)(TipBoxBook);
