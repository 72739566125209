import React from 'react';

import Logo from './Logo/logo';
import StyleDiv from './themed/StyleDiv';

import classes from './Banner.module.scss';

const banner = () => (
  <div id={ classes.logoBox }>
    <StyleDiv id={ classes.logo } bg="true" ma="true">
      <Logo />
    </StyleDiv>
    <StyleDiv id={ classes.subLogo } acc="true">
      <b>Learning tools for today&apos;s student.</b>
    </StyleDiv>
  </div>
);

export default banner;
