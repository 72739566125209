import { connect } from 'react-redux';
import styled from 'styled-components';
import Navbar from 'react-bootstrap/Navbar';

import { colorPick } from '../../shared/utility';

const styleNavbar = styled(Navbar)`
  background-color: ${(props) => (props.bg ? colorPick(props) : 'inherit')};
`;

const mapStateToProps = (state) => ({
  main: state.color.main,
  accent: state.color.accent,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(styleNavbar);
