import * as actionTypes from './actionTypes';

export const changeColor = (colors) => ({
  type: actionTypes.CHANGE_COLOR,
  colors,
});

export const changeColorSuccess = (colors) => ({
  type: actionTypes.CHANGE_COLOR_SUCCESS,
  colors,
});

export const setColor = (colors) => ({
  type: actionTypes.SET_COLOR,
  colors,
});

export const randomColor = () => ({ type: actionTypes.RANDOM_COLOR });

export const autoColorChange = () => ({ type: actionTypes.AUTO_COLOR_CHANGE });
