import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './SignIn.module.scss';
import * as actions from '../../store/actions/index';

import StyleDropdownItem from '../themed/StyleDropdownItem';
import Google from '../../assets/btnGoogle';

const SignIn = (props) => (
  <React.Fragment>
    <span className="white">User Sign In</span>
    <Dropdown.Divider className={classes.divider} />
    <StyleDropdownItem>
      <Button className={classes.facebook} onClick={() => props.onAuth('Facebook')}>
        <FontAwesomeIcon icon={['fab', 'facebook']} className="white" />
        <span>&nbsp;Sign In With Facebook</span>
      </Button>
    </StyleDropdownItem>
    <StyleDropdownItem>
      <Button className={classes.google} onClick={() => props.onAuth('Google')}>
        {/* <FontAwesomeIcon icon={['fab', 'google']} className="white" /> */}
        <Google />
        <span>&nbsp; Sign In With Google</span>
      </Button>
    </StyleDropdownItem>
  </React.Fragment>
);

SignIn.propTypes = {
  onAuth: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onAuth: (provider) => dispatch(actions.auth(provider)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
