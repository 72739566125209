import React from 'react';
import PropTypes from 'prop-types';

import StyleDiv from './themed/StyleDiv';
import * as classes from './SectionTitle.module.scss';
import Markdown from './Markdown';

const c = `${classes.sectionIntro} text-left`;

const SectionTitle = (props) => (
  <React.Fragment>
    <StyleDiv ma="true" className={classes.section}>
      <h5 className="text-left">
        <span> {props.title}</span>
      </h5>
    </StyleDiv>
    <div className={c}>
      <Markdown source={props.intro} />
    </div>
  </React.Fragment>
);

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
};

export default SectionTitle;
