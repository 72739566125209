import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';

import * as actions from '../store/actions/index';
import classes from './TOC.module.scss';

import StyleDropdownMenu from '../components/themed/StyleDropdownMenu';
import StyleDropdownItem from '../components/themed/StyleDropdownItem';
import StyleDropdownToggle from '../components/themed/StyleDropdownToggle';

const findTOC = (obj, bookName, change) => {
  const out = [];

  for (let i = 1; i < 30; i += 1) {
    if (obj && obj[`ch${i}`]) {
      out.push(
        <LinkContainer to={`../${i}/`} key={i} onClick={() => change({ bookName, chapter: i })}>
          <StyleDropdownItem>
            {i}: {obj[`ch${i}`][0].props.title}
          </StyleDropdownItem>
        </LinkContainer>,
      );
    }
  }
  return out;
};

class TOC extends Component {
  render() {
    return (
      <Nav>
        <Dropdown alignRight>
          <StyleDropdownToggle id={classes.TOC}>
            <FontAwesomeIcon icon={faThList} className="fa-lg" />
          </StyleDropdownToggle>
          <StyleDropdownMenu className={`text-left ${classes.dropownMenu}`} bg="true" ma="true">
            {findTOC(this.props.toc, this.props.bookName, this.props.tryChapterChange)}
          </StyleDropdownMenu>
        </Dropdown>
      </Nav>
    );
  }
}

TOC.propTypes = {
  tryChapterChange: PropTypes.func.isRequired,
  bookName: PropTypes.string,
  toc: PropTypes.object,
};

const mapStateToProps = (state) => ({
  main: state.color.main,
  accent: state.color.accent,
  bookName: state.chapter.bookName,
  toc: state.chapter.TOC,
});

const mapDispatchToProps = (dispatch) => ({
  tryChapterChange: (book) => dispatch(actions.changeChapter(book)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TOC);
