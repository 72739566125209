import { takeEvery, all } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import {
  changeColorSaga,
  randomColorSaga,
  setColorSaga,
  autoColorChangeSaga,
} from './color';
import {
  authLogoutSaga,
  authUserSaga,
  authCheckStateSaga,
} from './auth';
import {
  changeChapterSaga,
  setChapterSaga,
} from './chapter';

export function* watchColor() {
  yield all([
    takeEvery(actionTypes.CHANGE_COLOR, changeColorSaga),
    takeEvery(actionTypes.RANDOM_COLOR, randomColorSaga),
    takeEvery(actionTypes.AUTO_COLOR_CHANGE, autoColorChangeSaga),
    takeEvery(actionTypes.SET_COLOR, setColorSaga),
  ]);
}

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.AUTH_USER, authUserSaga),
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, authLogoutSaga),
    takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga),
  ]);
}

export function* watchChapter() {
  yield all([
    takeEvery(actionTypes.CHANGE_CHAPTER, changeChapterSaga),
    takeEvery(actionTypes.SET_CHAPTER, setChapterSaga),
  ]);
}

export default watchColor;
