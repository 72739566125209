import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './TipBox.module.scss';
import StyleTipButton from './themed/StyleTipButton';

const TipButton = () => (
  <StyleTipButton size="sm" className={ classes.tipButton } ma="true" bg="true">
    <FontAwesomeIcon icon="lightbulb" className="fa-2x white"/>
  </StyleTipButton>
);

export default TipButton;
