import _ from 'lodash';
import pluralize from 'pluralize';

import { prettifyNumber } from '../../shared/prettifyNumber';
import nonMetricUnits from '../../data/nonMetricTable';
import innerJoin from '../../shared/join';
import prefixes from '../../data/prefixTable';

// This is the data for the question
const fullTable = [];
for (let i = 0; i < 3; i += 1) {
  fullTable.push(
    innerJoin(nonMetricUnits[i].nonMetricConversions, prefixes, 'metricPrefix', 'prefix'),
  );
}

const NonmetricEx = {
  data: {
    question: '10 pounds = ____ grams',
    typeVal: 1,
    type: fullTable[1],
    unitVal1: 1,
    unitVal2: 2,
    val: 10,
    valString: '10',
    dir: 0,
    ans: 4500,
    ansString: '4,500',
  },

  setQuestion: (difficulty, ansString) => {
    const {
      ans, val, valString, unitVal1, unitVal2, type, typeVal, dir,
    } = NonmetricEx.data;

    let { question } = NonmetricEx.data;

    if (difficulty === 1) {
      question = `${valString} ${pluralize(type[unitVal1].nonMetric, val)} = ${ansString} ${type[unitVal2].metricPrefix}${pluralize(nonMetricUnits[typeVal].metricUnit, ans)}`;
    }
    if (difficulty === 2) {
      question = `${valString} ${type[unitVal2].metricPrefix}${pluralize(nonMetricUnits[typeVal].metricUnit, val)} = ${ansString} ${pluralize(type[unitVal1].nonMetric, ans)}`;
    }
    if (difficulty === 3) {
      if (dir === 0) {
        question = `${valString} ${pluralize(type[unitVal1].nonMetric, val)} = ${ansString} ${type[unitVal2].metricPrefix}${pluralize(nonMetricUnits[typeVal].metricUnit, ans)}`;
      } else {
        question = `${valString} ${type[unitVal2].metricPrefix}${pluralize(nonMetricUnits[typeVal].metricUnit, val)} = ${ansString} ${pluralize(type[unitVal1].nonMetric, ans)}`;
      }
    }

    NonmetricEx.data = {
      ...NonmetricEx.data, question,
    };

    return question;
  },

  getAns: (difficulty) => {
    const { ansString } = NonmetricEx.data;

    let { question } = NonmetricEx.data;

    question = NonmetricEx.setQuestion(difficulty, ansString);

    NonmetricEx.data = {
      ...NonmetricEx.data, question,
    };
    return NonmetricEx.data;
  },

  randomize: (difficulty) => {
    let {
      val, valString, unitVal1, unitVal2, type, typeVal, dir, question, ans, ansString,
    } = NonmetricEx.data;

    typeVal = _.random(2);
    type = fullTable[typeVal];
    unitVal1 = _.random(type.length - 1);
    unitVal2 = unitVal1;
    val = _.random(1, 100) * 10;
    valString = prettifyNumber(val);

    if (difficulty === 1) {
      ans = val * type[unitVal1].convert;
      ansString = prettifyNumber(ans, 2);
    }

    if (difficulty === 2) {
      ans = val / type[unitVal1].convert;
      ansString = prettifyNumber(ans, 3);
    }

    if (difficulty === 3) {
      // Make the conversion harder by requiring multiple steps
      if (typeVal === 0) {
        while (Math.abs(unitVal2 - unitVal1) !== 1) {
          unitVal1 = _.random(type.length - 1);
          unitVal2 = _.random(type.length - 1);
        }
      } else {
        while (Math.abs(unitVal2 - unitVal1) !== 2) {
          unitVal1 = _.random(type.length - 1);
          unitVal2 = _.random(type.length - 1);
        }
      }
      // Make the order of the conversion random
      dir = _.random(0, 1);
      if (dir === 0) {
        // Make the numbers more reasonable
        if (unitVal1 < unitVal2) {
          val /= 100;
          valString = prettifyNumber(val);
        }
        ans = (val * type[unitVal1].convert * type[unitVal1].scale) / type[unitVal2].scale;
        ansString = prettifyNumber(ans, 3);
      } else {
        // Make the numbers more reasonable
        if (unitVal1 > unitVal2) {
          val /= 100;
          valString = prettifyNumber(val);
        }
        ans = ((val / type[unitVal1].convert) * type[unitVal2].scale) / type[unitVal1].scale;
        ansString = prettifyNumber(ans, 3);
      }
    }

    NonmetricEx.data = {
      ...NonmetricEx.data,
      ans,
      val,
      valString,
      unitVal1,
      unitVal2,
      type,
      typeVal,
      dir,
      ansString,
    };

    question = NonmetricEx.setQuestion(difficulty, '_____');

    NonmetricEx.data = { ...NonmetricEx.data, question };

    return NonmetricEx.data;
  },
};

export default NonmetricEx;
