import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import TipBox from './TipBox';
import classes from './BoxContent.module.scss';

const BoxContent = () => (
  <Container fluid="true" id={ classes.boxContent }>
    <a name="Features">
      <Row>
        <TipBox content="The notes that you missed" num="1"/>
        <TipBox content="The friend who quizzes you" num="2"/>
        <TipBox content="The teacher who makes sense" num="3"/>
      </Row>
    </a>
  </Container>
);

export default BoxContent;
