import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Footer = (props) => {
  const year = new Date().getFullYear();
  return (
    <footer className="white mt-5" style={{ backgroundColor: props.main }}>
      <span className="font-weight-light font-size-small">
        Copyright &copy; {year} illuminotes LLC. All rights reserved.
      </span>
    </footer>
  );
};

Footer.propTypes = {
  main: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  main: state.color.main,
});

export default connect(mapStateToProps, null)(Footer);
