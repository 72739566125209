import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import remarkSubSuper from 'remark-sub-super';

import Markdown from './Markdown';
import * as classes from './Formula.module.scss';

const Formula = (props) => (
  <div className={classes.formula} style={{ border: `.2rem solid ${props.main}` }}>
    <b>{props.title}</b>
    <br />
    <span style={{ color: props.main }}>
      <Markdown
        source={props.formula}
        renderers={{ paragraph: 'span', sub: 'sub', sup: 'sup' }}
        plugins={[remarkSubSuper]}
      />
    </span>
    {props.title2 ? <br /> : null}
    <b>{props.title2}</b>
    {props.formula2 ? <br /> : null}
    <span style={{ color: props.main }}>
      <Markdown
        source={props.formula2}
        renderers={{ paragraph: 'span', sub: 'sub', sup: 'sup' }}
        plugins={[remarkSubSuper]}
      />
    </span>
    {props.title3 ? <br /> : null}
    <b>{props.title3}</b>
    {props.formula3 ? <br /> : null}
    <span style={{ color: props.main }}>
      <Markdown
        source={props.formula3}
        renderers={{ paragraph: 'span', sub: 'sub', sup: 'sup' }}
        plugins={[remarkSubSuper]}
      />
    </span>
  </div>
);

Formula.propTypes = {
  title: PropTypes.string.isRequired,
  formula: PropTypes.string.isRequired,
  main: PropTypes.string.isRequired,
  title2: PropTypes.string,
  formula2: PropTypes.string,
  title3: PropTypes.string,
  formula3: PropTypes.string,
};

const mapStateToProps = (state) => ({
  main: state.color.main,
});

export default connect(mapStateToProps, null)(Formula);
