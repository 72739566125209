import * as actionTypes from './actionTypes';

export const changeChapter = (book) => ({
  type: actionTypes.CHANGE_CHAPTER,
  book,
});

export const changeChapterSuccess = (book) => ({
  type: actionTypes.CHANGE_CHAPTER_SUCCESS,
  book,
});

export const setChapter = (book) => ({
  type: actionTypes.SET_CHAPTER,
  book,
});

export const autoChapterChange = () => ({ type: actionTypes.AUTO_CHAPTER_CHANGE });
