export const CHANGE_COLOR = 'CHANGE_COLOR';
export const CHANGE_COLOR_SUCCESS = 'CHANGE_COLOR_SUCCESS';
export const SET_COLOR = 'SET_COLOR';
export const RANDOM_COLOR = 'RANDOM_COLOR';
export const AUTO_COLOR_CHANGE = 'AUTO_COLOR_CHANGE';

export const AUTH_USER = 'AUTH_USER';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';

export const SET_CHAPTER = 'SET_CHAPTER';
export const CHANGE_CHAPTER = 'CHANGE_CHAPTER';
export const CHANGE_CHAPTER_SUCCESS = 'CHANGE_CHAPTER_SUCCESS';
export const AUTO_CHAPTER_CHANGE = 'AUTO_CHAPTER_CHANGE';
