import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';

import classes from './TipBox.module.scss';
import TipButton from './TipButton';

const TipBox = (props) => {
  const tipId = `tip-text-${props.num}`;
  return (
    <Col xs="12" md="4" className={`d-flex ${classes.tip}`}>
      <TipButton />
      <div className={`d-flex ${classes.tipBox}`} style={{ border: `solid 2px ${props.main}` }}>
        <span className="font-weight-bold" id={classes[tipId]} style={{ color: props.main }}>
          {props.content}
        </span>
      </div>
    </Col>
  );
};

TipBox.propTypes = {
  main: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  num: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  main: state.color.main,
});

export default connect(mapStateToProps)(TipBox);
