import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  createStore, applyMiddleware, compose, combineReducers,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import Analytics from 'react-router-ga';

import './index.scss';
import App from './App';
import colorReducer from './store/reducers/color';
import authReducer from './store/reducers/auth';
import chapterReducer from './store/reducers/chapter';
import { watchColor, watchAuth, watchChapter } from './store/sagas/index';
import * as serviceWorker from './serviceWorker';

const rootReducer = combineReducers({
  color: colorReducer,
  auth: authReducer,
  chapter: chapterReducer,
});

const sagaMiddleware = createSagaMiddleware();

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
/* eslint-enable */

sagaMiddleware.run(watchColor);
sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchChapter);

const app = (
  <Provider store={store}>
    <Router >
      <Analytics id="UA-81161910-1">
        <App />
      </Analytics>
    </Router>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
