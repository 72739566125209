import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';

const DropdownToggle = ({ ...rest }) => (<Dropdown.Toggle {...rest} />);

const StyleDropdownToggle = styled(DropdownToggle)`
  background-color: transparent !important;
  border: 0;
  &:hover, &:focus, &:active {
    background-color: transparent !important; 
    border: 0;
    color: ${(props) => props.accent} !important;
  }
  &:focus {
    background-color: transparent !important;
    border: 0;
    box-shadow: 0 0 0 transparent !important;
  }
`;

DropdownToggle.propTypes = {
  $as: PropTypes.object,
};

const mapStateToProps = (state) => ({
  accent: state.color.accent,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StyleDropdownToggle);
