const nonMetricUnits = [
  {
    quantity: 'distance',
    metricUnit: 'meter',
    metricBase: '',
    metricAbbr: 'm',
    nonMetricConversions: [
      {
        nonMetric: 'mile', nonMetricAbbr: 'mi', metricPrefix: 'kilo', convert: 1.61,
      },
      {
        nonMetric: 'yard', nonMetricAbbr: 'yd', metricPrefix: '', convert: 0.91,
      },
      {
        nonMetric: 'inch', nonMetricAbbr: 'in', metricPrefix: 'centi', convert: 2.54,
      },
    ],
  },
  {
    quantity: 'mass',
    metricUnit: 'gram',
    metricBase: 'kilo',
    metricAbbr: 'g',
    nonMetricConversions: [
      {
        nonMetric: 'ton', nonMetricAbbr: 'ton', metricPrefix: 'kilo', convert: 907.2,
      },
      {
        nonMetric: 'pound', nonMetricAbbr: 'lb', metricPrefix: 'kilo', convert: 0.45,
      },
      {
        nonMetric: 'ounce', nonMetricAbbr: 'oz', metricPrefix: '', convert: 28.35,
      },
    ],
  },
  {
    quantity: 'volume',
    metricUnit: 'liter',
    metricBase: '',
    metricAbbr: 'L',
    nonMetricConversions: [
      {
        nonMetric: 'gallon', nonMetricAbbr: 'gal', metricPrefix: '', convert: 3.79,
      },
      {
        nonMetric: 'quart', nonMetricAbbr: 'qt', metricPrefix: '', convert: 0.95,
      },
      {
        nonMetric: 'cup', nonMetricAbbr: 'cup', metricPrefix: 'milli', convert: 236.6,
      },
    ],
  },
];

export default nonMetricUnits;
