import React, { Component } from 'react';

import classOptions from './classNameTypes';
import * as classNames from './classNameTypes';
import classMap from '../shared/classMap';

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      currentElement: {
        type: classNames.ChapterTitle,
        props: {
          title: 'Title',
          intro: 'Intro',
        },
      },
      classOptionsItems: null,
    };
    this.putElementsHandler = (event) => {
      event.preventDefault();
      return null;
    };

    this.addElementsHandler = (event) => {
      this.state.elements.push();
      return null;
    };

    this.classChangeHandler = (event) => {
      const chapterTitleOptions = (
        <div>
          <div>
            <label htmlFor="title">Title:&nbsp;</label>
            <input name="title" type="text" defaultValue="title"/>
          </div>
          <div>
            <label htmlFor="intro">Introduction:&nbsp;</label>
            <input name="intro" type="text" defaultValue="intro text..."/>
          </div>
        </div>
      );
      const definitionOptions = (
        <div>
          <div>
            <label htmlFor="term">Term:&nbsp;</label>
            <input name="term" type="text" defaultValue="term"/>
          </div>
          <div>
            <label htmlFor="definition">Definition:&nbsp;</label>
            <input name="definition" type="text" defaultValue="definition"/>
          </div>
        </div>
      );


      switch (event.target.value) {
      case classNames.ChapterTitle:
        this.setState({ classOptionsItems: chapterTitleOptions });
        this.setState({ currentElement: {
          type: classNames.ChapterTitle,
          props: {
            title: 'Title',
            intro: 'Intro',
          },
        },
        });
        break;
      case classNames.SectionTitle:
        this.setState({ classOptionsItems: chapterTitleOptions });
        this.setState({ currentElement: {
          type: classNames.SectionTitle,
          props: {
            title: 'Title',
            intro: 'Intro',
          },
        },
        });
        break;
      case classNames.Definition:
        this.setState({ classOptionsItems: definitionOptions });
        this.setState({ currentElement: {
          type: classNames.Definition,
          props: {
            term: 'Term',
            def: 'Definition',
          },
        },
        });
        break;
      default:
        this.setState({ classOptionsItems: null });
        break;
      }
    };
  }

  render() {
    const classOptionsList = classOptions.map((elt, key) => (
      <option value={elt} key={key}>
        {elt}
      </option>
    ));
    return (
      <React.Fragment>
        <form onSubmit={ this.putElementsHandler } style={{ marginTop: '3em' }}>
          <div>
            <label htmlFor="className">Element type:&nbsp;</label>
            <select name="className" onChange={ this.classChangeHandler } defaultValue="Chapter Title">
              { classOptionsList }
            </select>
             { this.state.classOptionsItems }
          </div>
          <input type="submit"/>
        </form>
        <hr/>
        Current Element: <br/>
        { React.createElement(classMap(this.state.currentElement.type), this.state.currentElement.props) }
        <br/>
        <br/>
          <button> Add </button>
          <hr/>
      </React.Fragment>

    );
  }
}

export default Edit;
