import * as actionTypes from './actionTypes';

export const authStart = (provider) => ({
  type: actionTypes.AUTH_START,
  provider,
});

export const authSuccess = (userId, userEmail, userDisplayName) => ({
  type: actionTypes.AUTH_SUCCESS,
  userId,
  userEmail,
  userDisplayName,
});

export const authFail = (error) => ({
  type: actionTypes.AUTH_FAIL,
  error,
});

export const logout = () => ({
  type: actionTypes.AUTH_INITIATE_LOGOUT,
});

export const logoutSuccess = () => ({
  type: actionTypes.AUTH_LOGOUT,
});

export const auth = (provider) => ({
  type: actionTypes.AUTH_USER,
  provider,
});

export const autoSignIn = () => ({
  type: actionTypes.AUTH_CHECK_STATE,
});
