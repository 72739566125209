import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  provider: null,
  isLoggedIn: false,
  userId: null,
  userEmail: null,
  userDisplayName: null,
  error: null,
  loading: false,
};

const authStart = (state, action) => updateObject(state,
  { provider: action.provider, error: null, loading: true });

const authSuccess = (state, action) => updateObject(state, {
  isLoggedIn: true,
  userId: action.userId,
  userEmail: action.userEmail,
  userDisplayName: action.userDisplayName,
  error: null,
  loading: false,
});

const authFail = (state, action) => updateObject(state, { error: action.error, loading: false });

const authLogout = (state) => updateObject(state, {
  isLoggedIn: false,
  userId: null,
  userEmail: null,
  userDisplayName: null,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.AUTH_START:
    return authStart(state, action);
  case actionTypes.AUTH_SUCCESS:
    return authSuccess(state, action);
  case actionTypes.AUTH_FAIL:
    return authFail(state, action);
  case actionTypes.AUTH_LOGOUT:
    return authLogout(state);
  default:
    return state;
  }
};

export default reducer;
