const density = [
  { material: 'air', density: 1.3 },
  { material: 'ice', density: 920 },
  { material: 'water', density: 1000 },
  { material: 'iron', density: 7860 },
  { material: 'silver', density: 10500 },
  { material: 'gold', density: 19300 },
];

export default density;
