import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Markdown from './Markdown';
import StyleDiv from './themed/StyleDiv';

import * as classes from './ChapterTitle.module.scss';

const c = `${classes.chapterIntro} gray text-left`;

const ChapterTitle = (props) => (
  <React.Fragment>
    <StyleDiv id="title-bar" acc="true" style={{ marginTop: '3em' }}>
      <span id="title">
        <h3 className="text-left">
          <span>
            Chapter {props.chapter}: {props.title}
          </span>
        </h3>
      </span>
      <hr />
    </StyleDiv>
    <div className={c}>
      <Markdown source={props.intro} />
    </div>
  </React.Fragment>
);

ChapterTitle.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  chapter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const mapStateToProps = (state) => ({
  chapter: state.chapter.chapter,
});

export default connect(mapStateToProps, null)(ChapterTitle);
