import _ from 'lodash';
import pluralize from 'pluralize';

import { prettifyNumber } from '../../shared/prettifyNumber';
import time from '../../data/timeTable';

// This is the data for the question
const data = _.concat({ unit: 'millisecond', abbr: 'ms', scale: 0.001 }, time);

const TimeEx = {
  data: {
    question: '1 hour = ____ seconds',
    unitVal1: 3,
    unitVal2: 1,
    val: 1,
    valString: '1',
    ans: 3600,
    ansString: '3,600',
  },

  setQuestion: (difficulty, ansString) => {
    let { question } = TimeEx.data;
    const {
      unitVal1, unitVal2, val, valString, ans,
    } = TimeEx.data;

    question = `${valString} ${pluralize(data[unitVal1].unit, val)} = ${ansString} ${pluralize(
      data[unitVal2].unit,
      ans,
    )}`;
    TimeEx.data = { ...TimeEx.data, question };

    return question;
  },

  getAns: (difficulty) => {
    const { ansString } = TimeEx.data;

    let { question } = TimeEx.data;

    question = TimeEx.setQuestion(difficulty, ansString);

    TimeEx.data = {
      ...TimeEx.data,
      question,
    };
    return TimeEx.data;
  },

  randomize: (difficulty) => {
    let {
      unitVal1, unitVal2, val, valString, ans, ansString, question,
    } = TimeEx.data;

    unitVal1 = _.random(data.length - 1);
    unitVal2 = unitVal1;
    val = _.random(1, 100);

    if (difficulty === 1) {
      while (unitVal1 === 0 || unitVal2 === 0 || Math.abs(unitVal1 - unitVal2) !== 1) {
        unitVal1 = _.random(data.length - 1);
        unitVal2 = _.random(data.length - 1);
      }
      if (unitVal2 > unitVal1) {
        val *= 10;
      }
    }

    if (difficulty === 2) {
      while (unitVal1 === 0 || unitVal2 === 0 || Math.abs(unitVal1 - unitVal2) !== 2) {
        unitVal1 = _.random(data.length - 1);
        unitVal2 = _.random(data.length - 1);
      }
      if (unitVal2 > unitVal1) {
        val *= 100;
      }
    }

    if (difficulty === 3) {
      while (Math.abs(unitVal1 - unitVal2) !== 3) {
        unitVal1 = _.random(data.length - 1);
        unitVal2 = _.random(data.length - 1);
      }
      if (unitVal2 > unitVal1) {
        val *= 1000;
      } else {
        val /= 10;
      }
    }

    valString = prettifyNumber(val);
    ans = (val * data[unitVal1].scale) / data[unitVal2].scale;
    ansString = prettifyNumber(ans, 3);

    TimeEx.data = {
      ...TimeEx.data,
      ans,
      val,
      valString,
      unitVal1,
      unitVal2,
      ansString,
    };

    question = TimeEx.setQuestion(difficulty, '_____');

    TimeEx.data = { ...TimeEx.data, question };

    return TimeEx.data;
  },
};

export default TimeEx;
