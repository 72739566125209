import { prettifyNumber } from '../../shared/prettifyNumber';

// This is the data for the question

const DensityEx = {
  data: {
    question: 'A 126-kg rectangular brick is 0.3 meters long, 0.2 meters wide, and 0.2 meters thick.  Its density is _____ kg/m^3^',
    mass: 126,
    length: 0.3,
    width: 0.2,
    height: 0.2,
    density: 10500,
    volume: 0.012,
    ans: 10500,
    ansString: '10,500',
  },

  setQuestion: (difficulty, ansString) => {
    let { question } = DensityEx.data;
    const {
      mass, length, width, height, density,
    } = DensityEx.data;

    if (difficulty === 1) {
      question = `A ${prettifyNumber(mass, 3)}-kg rectangular brick is ${prettifyNumber(length)} meters long, ${prettifyNumber(width)} meters wide, and ${prettifyNumber(height)} meters thick.  Its density is ${ansString} kg/m^3^. `;
    } else if (difficulty === 2) {
      question = `A rectangular brick of density ${prettifyNumber(density, 3)} kg/m^3^ is ${prettifyNumber(length)} meters long, ${prettifyNumber(width)} meters wide, and ${prettifyNumber(height)} meters thick.  Its mass is ${ansString} kg.`;
    } else {
      question = `A ${prettifyNumber(mass, 3)}-kg rectangular brick has a density of ${prettifyNumber(density, 4)} kg/m^3^.  Its volume is ${ansString} m^3^. `;
    }
    DensityEx.data = { ...DensityEx.data, question };

    return question;
  },

  getAns: (difficulty) => {
    const { ansString } = DensityEx.data;

    let { question } = DensityEx.data;

    question = DensityEx.setQuestion(difficulty, ansString);

    DensityEx.data = {
      ...DensityEx.data,
      question,
    };
    return DensityEx.data;
  },

  randomize: (difficulty) => {
    let {
      mass, length, width, height, volume, density, ans, ansString, question,
    } = DensityEx.data;

    mass = Math.round(Math.random() * 150 + 15);
    length = Math.round(Math.random() * 15 + 1) / 10;
    width = Math.round(Math.random() * 15 + 1) / 10;
    height = Math.round(Math.random() * 10 * length + 1) / 10;
    volume = length * width * height;
    density = mass / volume;

    if (difficulty === 1) {
      ans = density;
    } else if (difficulty === 2) {
      ans = mass;
    } else {
      ans = volume;
    }

    ansString = prettifyNumber(ans, 3);

    DensityEx.data = {
      ...DensityEx.data,
      ans,
      ansString,
      mass,
      length,
      width,
      height,
      density,
      volume,
    };

    question = DensityEx.setQuestion(difficulty, '_____');

    DensityEx.data = { ...DensityEx.data, question };

    return DensityEx.data;
  },
};

export default DensityEx;
