export function commafy(yourNumber, symbol = ',') {
  const neg = parseFloat(yourNumber) < 0;
  const pos = Math.abs(parseFloat(yourNumber));
  let out;
  if (pos > 1) {
    // Seperates the components of the number
    const n = pos.toString().split('.');
    // Comma-fies the first part
    n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, symbol);
    // Combines the two sections
    out = n.join('.');
  } else {
    out = pos.toString();
  }
  if (neg) {
    out = `-${out}`;
  }
  return out;
}

// Takes a number and returns a string w/  spaces in the decimal part
export function spacefy(yourNumber) {
  const neg = parseFloat(yourNumber) < 0;
  const pos = Math.abs(parseFloat(yourNumber));
  let out;
  // Seperates the components of the number
  const n = pos.toString().split('.');
  if (n[1]) {
    // Look at the decimal part
    const dec = n[1].split('');
    // If there are more than 3 decimal places add spaces
    if (dec.length > 3) {
      let spaces = 0;
      // Add a space every 3 decimals
      for (let j = 3; j < dec.length - spaces; j += 3) {
        dec.splice(j + spaces, 0, ' '); // Add in a space
        spaces += 1;
      }
      n[1] = dec.join('');
    }
  }
  // Combines the two sections
  out = n.join('.');
  if (neg) {
    out = `-${out}`;
  }
  return out;
}

// Returns a string of the submitted number with a certain number of significant figures.
// sig determins the # of significant figures
// decSpace determines if the decimal side should have spaces between the zeros
export function prettifyNumber(yourNumber, sig = 2, decSpace = false, symbol = ',') {
  let out;
  if (Math.abs(yourNumber) < 0.000001) {
    const lead = decSpace ? '000 000 ' : '000000';
    const n = prettifyNumber(yourNumber * 1000000, sig, decSpace, symbol).split('.');
    n[1] = lead.concat(n[1]);
    out = n.join('.');
  } else if (decSpace && Math.round(yourNumber).toPrecision(sig) !== yourNumber.toPrecision(sig)) {
    const n = yourNumber.toPrecision(sig).split('.');
    if (n[1]) {
      n[1] = spacefy(parseFloat(`0.${n[1]}`));
      n[1] = n[1].slice(2);
    }
    n[0] = commafy(n[0], symbol);
    out = n.join('.');
  } else {
    out = commafy(yourNumber.toPrecision(sig), symbol);
  }
  return out;
}
