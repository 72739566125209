export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const colorPick = (props) => {
  if (props.acc) {
    return props.accent;
  }
  if (props.ma) {
    return props.main;
  }
  return 'inherit';
};
