import { connect } from 'react-redux';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';

import { colorPick } from '../../shared/utility';

const styleDropdownMenu = styled(Dropdown.Menu)`
  background-color: ${(props) => (props.bg ? colorPick(props) : 'inherit')};
`;

const mapStateToProps = (state) => ({
  main: state.color.main,
  accent: state.color.accent,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(styleDropdownMenu);
