export const ChapterTitle = 'ChapterTitle';
export const SectionTitle = 'SectionTitle';
export const Definition = 'Definition';
export const Exercise = 'Exercise';
export const TipBoxBook = 'TipBoxBook';
export const Other = 'Other';
export const Text = 'Text';
export const List = 'List';
export const Formula = 'Formula';

export const classOptions = [
  ChapterTitle,
  Definition,
  Exercise,
  SectionTitle,
  TipBoxBook,
  Other,
  Text,
  List,
  Formula,
];

export default classOptions;
