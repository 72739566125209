import { put, call } from 'redux-saga/effects';

import * as actions from '../actions/index';
import firebaseApp from '../../config/Firebase';

const colorsList = [
  ['#0074D9', '#FFDC00'],
  ['#6339D9', '#0AFE4D'],
  ['#A333D9', '#25FF47'],
  ['#0097D9', '#FF6C0E'],
  ['#00D6BB', '#FF3195'],
  ['#9731EC', '#20A1F0'],
  ['#531B93', '#FF2F92'],
  ['#063F70', '#00e5ff'],
];

const setColor = (colors) => {
  if (colors.main) {
    sessionStorage.setItem('main', colors.main);
  }
  if (colors.accent) {
    sessionStorage.setItem('accent', colors.accent);
  }
};

export function* setColorSaga(action) {
  // First update local data
  yield call(setColor, action.colors);

  // Then update database
  const userId = yield sessionStorage.getItem('userId');

  if (userId) {
    const ref = firebaseApp.database().ref(`users/${userId}`);
    if (action.colors.main) {
      yield call([ref, ref.update], { mainColor: action.colors.main });
    }
    if (action.colors.accent) {
      yield call([ref, ref.update], { accentColor: action.colors.accent });
    }
  }
}

export function* changeColorSaga(action) {
  yield put(actions.setColor(action.colors));
  yield put(actions.changeColorSuccess(action.colors));
}

export function* randomColorSaga() {
  const index = Math.floor(Math.random() * colorsList.length);
  const colors = {
    main: colorsList[index][0],
    accent: colorsList[index][1],
  };
  yield put(actions.setColor(colors));
  yield put(actions.changeColorSuccess(colors));
}

export function* autoColorChangeSaga() {
  const colors = {
    main: sessionStorage.getItem('main') || '#0074D9',
    accent: sessionStorage.getItem('accent') || '#FFDC00',
  };
  yield call(setColor, colors);
  yield put(actions.changeColorSuccess(colors));
}

export default changeColorSaga;
