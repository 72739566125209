import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

import { colorPick } from '../../shared/utility';

const styleTipButton = styled(Button)`
  &,
  &:focus,
  &:hover,
  &:active {
    background-color: ${(props) => (props.bg ? colorPick(props) : 'inherit')} !important;
    color: ${(props) => (!props.bg ? colorPick(props) : 'inherit')};
    fill: ${(props) => (!props.bg ? colorPick(props) : 'inherit')};
    border: solid 2px ${(props) => colorPick(props)} !important;
    box-shadow: 0 0 0.5rem ${(props) => props.accent} !important;
    cursor: default;
  }
`;

const mapStateToProps = (state) => ({
  main: state.color.main,
  accent: state.color.accent,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(styleTipButton);
