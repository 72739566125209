import _ from 'lodash';
import pluralize from 'pluralize';

import { prettifyNumber } from '../../shared/prettifyNumber';
import prefixes from '../../data/prefixTable';

// This is the data for the question
const pre = _.map(prefixes, 'prefix');
const preConvert = _.map(prefixes, 'scale');
const unit = ['meter', 'gram', 'liter'];

const PrefixEx = {
  data: {
    question: '10 kilograms = ____ grams',
    pre1: pre[2],
    pre2: pre[3],
    unitVal: unit[1],
    val: 10,
    valString: '10',
    ans: 10000,
    ansString: '10,000',
  },

  getAns: () => {
    const question = `${PrefixEx.data.val} ${PrefixEx.data.pre1}${pluralize(PrefixEx.data.unitVal, PrefixEx.data.val)} = ${PrefixEx.data.ansString} ${PrefixEx.data.pre2}${pluralize(PrefixEx.data.unitVal, PrefixEx.data.ans)}`;
    PrefixEx.data = { ...PrefixEx.data, question };
    return PrefixEx.data;
  },

  randomize: (difficulty) => {
    let {
      pre1, pre2, unitVal, val, ans, ansString, valString, question,
    } = PrefixEx.data;

    pre1 = _.sample(pre);
    pre2 = pre1;
    unitVal = _.sample(unit);

    // Easy
    if (difficulty === 1) {
      // Make the problem easy by only converting to the unit with no prefix
      while (pre1 === '' || pre1 === 'nano' || pre1 === 'giga') {
        pre1 = _.sample(pre);
      }
      pre2 = '';
    }

    // Med
    if (difficulty === 2) {
      // Make the problem med by only converting from a unit with no prefix
      while (pre2 === '' || pre2 === 'nano' || pre2 === 'giga') {
        pre2 = _.sample(pre);
      }
      pre1 = '';
    }

    // Hard
    if (difficulty === 3) {
      // Make the problem hard by requiring 2 conversions
      while (
        pre1 === pre2
        || Math.abs(_.indexOf(pre, pre2) - _.indexOf(pre, pre1)) > 3
        || pre1 === ''
        || pre2 === ''
        || pre1 === 'nano'
        || pre2 === 'nano'
      ) {
        pre1 = _.sample(pre);
        pre2 = _.sample(pre);
      }
    }

    // Choose a random value between 100 & 10,000
    // Make the conversion reasonable by determining whether it's converting up or down
    if (_.indexOf(pre, pre1) < _.indexOf(pre, pre2)) {
      val = _.random(1, 100) / 100;
    } else {
      val = _.random(1, 100) * 100;
    }
    valString = prettifyNumber(val);

    // Calculate answer
    ans = (val * preConvert[_.indexOf(pre, pre1)]) / preConvert[_.indexOf(pre, pre2)];
    ansString = prettifyNumber(ans);

    question = `${val} ${pre1}${pluralize(unitVal, val)} = ____ ${pre2}${pluralize(unitVal)}`;

    PrefixEx.data = {
      ...PrefixEx.data,
      pre1,
      pre2,
      unitVal,
      val,
      valString,
      ans,
      ansString,
      question,
    };

    return PrefixEx.data;
  },
};

export default PrefixEx;
