import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import NavItem from 'react-bootstrap/NavItem';
import { LinkContainer } from 'react-router-bootstrap';

import StyleDropdownMenu from './themed/StyleDropdownMenu';
import StyleDropdownItem from './themed/StyleDropdownItem';
import StyleDropdownToggle from './themed/StyleDropdownToggle';

const MainMenuOptions = () => (
  <React.Fragment>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto d-flex justify-content-around w-100" >
        <Dropdown as={NavItem}>
          <StyleDropdownToggle>About</StyleDropdownToggle>
          <StyleDropdownMenu bg="true" ma="true">
            <StyleDropdownItem href="#Features">Features</StyleDropdownItem>
            <StyleDropdownItem href="#Backed">Backed By Research</StyleDropdownItem>
            <StyleDropdownItem href="#About">About Us</StyleDropdownItem>
          </StyleDropdownMenu>
        </Dropdown>
        <Dropdown as={NavItem}>
          <StyleDropdownToggle>Math</StyleDropdownToggle>
          <StyleDropdownMenu bg="true" ma="true">
            <LinkContainer to="/book/Algebra2/">
              <StyleDropdownItem>Algebra 2</StyleDropdownItem>
            </LinkContainer>
            <LinkContainer to="/book/Precalculus/">
              <StyleDropdownItem>Precalculus</StyleDropdownItem>
            </LinkContainer>
            <LinkContainer to="/book/Calculus/">
              <StyleDropdownItem>Calculus</StyleDropdownItem>
            </LinkContainer>
          </StyleDropdownMenu>
        </Dropdown>
        <Dropdown as={NavItem}>
          <StyleDropdownToggle>Science</StyleDropdownToggle>
          <StyleDropdownMenu bg="true" ma="true">
            <LinkContainer to="/book/IntroductoryPhysics/">
              <StyleDropdownItem>Introductory Physics</StyleDropdownItem>
            </LinkContainer>
            <LinkContainer to="/book/APPhysics1/">
              <StyleDropdownItem>AP Physics 1</StyleDropdownItem>
            </LinkContainer>
            <LinkContainer to="/book/APPhysicsC/">
              <StyleDropdownItem>AP Physics C</StyleDropdownItem>
            </LinkContainer>
          </StyleDropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar.Collapse>
  </React.Fragment>
);

export default MainMenuOptions;
