import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import classes from './logo.module.scss';

const Logo = (props) => {
  const bulbStyle = [classes.bulbStyle, 'white'].join(' ');
  const textStyle = [classes.textStyle, 'white'].join(' ');

  const glowStyle = {
    filter: 'url(#filter15512)',
    fill: props.accent,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 498 140" style={ { backgroundColor: props.main } }>
      <defs>
        <filter
          id="filter15512"
          className={ classes.filterStyle }
          collect="always"
          x="-0.16113102"
          width="1.33"
          y="-0.12629189"
          height="1.25">
          <feGaussianBlur
            id="feGaussianBlur15514"
            collect="always"
            stdDeviation="2.75"/>
        </filter>
      </defs>
      <g id="layer4" >
        <path
          id="innerGlow"
          className={ classes.innerGlowStyle }
          style={glowStyle}
          d="m 22.081381,60.332038 c -1.677175,-4.586347 -3.327131,-9.182649 -4.94975,-13.78858 l 3.53554,-13.43503 9.89949,-9.19239 13.08148,-0.7071 9.54594,5.65685 4.94975,9.54594 -0.70711,12.72792 c -1.316492,3.055562 -2.612893,6.11978 -3.88909,9.19239 -1.317947,3.173128 -2.614347,6.355206 -3.88908,9.54594 l -9.54595,5.65686 -14.49568,-5.65686 c -1.191629,-3.177102 -2.370155,-6.359118 -3.53554,-9.54594 z"/>
        <path
          id="middleGlow"
          className={ classes.middleGlowStyle }
          style={glowStyle}
          d="m 29.734661,77.616188 -12,-4.5 -4.99999,-12 -3.5,-11.5 -1,-12 8.99999,-16 14.5,-9 11,0 15,5.5 7.00001,9 1,3 -4.5,10 -2.5,26.5 -8.50001,10 -20.5,1 z"/>
        <path
          id="outerGlow"
          className={ classes.outerGlowStyle }
          style={glowStyle}
          d="m 47.734661,91.116188 -10.25,2.75 -6.49999,0 -11.5,-7 -6.75,-9.5 -0.5,-17.5 -2,-18 2.5,-12 12,-10.5 9.49999,-3.75 10.75,1.25 9.5,2 5.25,4.25 3.50001,4.75 1.5,5 3.25,14.75 -1,-9 3.25,15.75 c -1.151417,2.839581 -2.318106,5.672969 -3.5,8.5 -1.152237,2.756092 -2.318925,5.506142 -3.5,8.25 -2.413122,2.509746 -4.747967,5.094754 -7,7.75 -2.071141,2.441966 -4.07224,4.943337 -6.00001,7.5 l -2.5,4.75 z"/>
        <path
          id="bulb"
          className={ bulbStyle}
          d="m 38.334911,28.705908 c -8.27648,-0.28475 -15.71798,8.13427 -14.7416,16.80695 0.24823,3.82223 2.15223,7.24567 4.39533,10.15388 2.20186,3.88576 3.24595,8.39204 3.41593,12.88504 0.43289,2.5068 3.03651,3.49659 5.1848,3.58713 2.45733,0.21487 5.02953,-0.0226 7.32089,-0.98596 2.04362,-1.5622 1.60372,-4.49329 2.02102,-6.79257 0.49052,-4.42672 2.35868,-8.5708 4.99782,-12.02296 4.1302,-6.63755 2.16668,-16.47988 -4.1293,-20.844 -2.46888,-1.7939 -5.45811,-2.81042 -8.46489,-2.78751 z" />
      </g>
      <g id="layer3">
        <text
          className={ textStyle }
          x="26.2"
          y="117"
          id="text"
          transform="scale(1.25, 0.8)">illuminotes</text>
      </g>
    </svg>
  );
};

Logo.propTypes = {
  main: PropTypes.string.isRequired,
  accent: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  main: state.color.main,
  accent: state.color.accent,
});

export default connect(mapStateToProps)(Logo);
