import { put, call } from 'redux-saga/effects';

import * as actions from '../actions/index';
import firebaseApp from '../../config/Firebase';
import 'firebase/functions';

const setChapter = (book) => {
  if (book.bookName) {
    sessionStorage.setItem('bookName', book.bookName);
  }
  if (book.chapter) {
    sessionStorage.setItem('chapter', book.chapter);
  }
};

export function* setChapterSaga(action) {
  // First update local data
  yield call(setChapter, action.book);

  // Then update database
  const userId = yield sessionStorage.getItem('userId');

  if (userId) {
    const ref = firebaseApp.database().ref(`users/${userId}`);
    if (action.book.bookName) {
      yield call([ref, ref.update], { bookName: action.book.bookName });
    }
    if (action.book.chapter) {
      yield call([ref, ref.update], { chapter: action.book.chapter });
    }
  }
}

export function* getChapterData(book) {
  const chap = book.chapter || '1';

  const refChap = firebaseApp.database().ref(`books/${book.bookName}/ch${chap}`);
  const refBook = firebaseApp.database().ref(`books/${book.bookName}`);

  const result = yield call([refChap, refChap.once], 'value');
  const resultTOC = yield call([refBook, refBook.once], 'value');

  return { ...book, elements: result.val(), TOC: resultTOC.val() };
}

export function* changeChapterSaga(action) {
  const book = yield call(getChapterData, action.book);
  yield put(actions.setChapter(book));
  yield put(actions.changeChapterSuccess(book));
}

export function* autoChapterChangeSaga() {
  const book = {
    bookName: sessionStorage.getItem('bookName'),
    chapter: sessionStorage.getItem('chapter'),
  };
  yield call(setChapter, book);
  yield put(actions.changeChapterSuccess(book));
}
