import ChapterTitle from '../components/ChapterTitle';
import SectionTitle from '../components/SectionTitle';
import Definition from '../components/Definition';
import Exercise from '../components/Exercise';
import TipBoxBook from '../components/TipBoxBook';
import Other from '../components/Other';
import Text from '../components/Text';
import List from '../components/List';
import Formula from '../components/Formula';
import * as classNames from '../containers/classNameTypes';

export const classMap = (name) => {
  switch (name) {
  case classNames.ChapterTitle: return ChapterTitle;
  case classNames.SectionTitle: return SectionTitle;
  case classNames.Definition: return Definition;
  case classNames.TipBoxBook: return TipBoxBook;
  case classNames.Exercise: return Exercise;
  case classNames.Other: return Other;
  case classNames.Text: return Text;
  case classNames.List: return List;
  case classNames.Formula: return Formula;
  default: return {};
  }
};

export default classMap;
