import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  chapter: 1,
  bookName: null,
  elements: [],
};

const changeChapterSuccess = (state, action) => updateObject(state, action.book);

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.CHANGE_CHAPTER_SUCCESS: return changeChapterSuccess(state, action);
  default:
    return state;
  }
};

export default reducer;
