import React from 'react';
import PropTypes from 'prop-types';

import Markdown from './Markdown';

const Text = (props) => (
  <div className="text-left">
    <Markdown source={props.content} />
  </div>
);

Text.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Text;
