import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as classes from './Definition.module.scss';
import Markdown from './Markdown';

const Definition = (props) => (
  <div className={ classes.definition } style={ { border: `.2rem solid ${props.accent}` } }>
    <b>{ props.term }</b> - <Markdown source={ props.def } />
    { props.term2 ? <br/> : null }
    <b>{ props.term2 }</b> { props.term2 ? '-' : null } <Markdown source={ props.def2 } />
    { props.term3 ? <br/> : null }
    <b>{ props.term3 }</b> { props.term3 ? '-' : null } <Markdown source={ props.def3 } />
    { props.term4 ? <br/> : null }
    <b>{ props.term4 }</b> { props.term4 ? '-' : null } <Markdown source={ props.def4 } />
  </div>
);

Definition.propTypes = {
  accent: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
  def: PropTypes.string.isRequired,
  term2: PropTypes.string,
  def2: PropTypes.string,
  term3: PropTypes.string,
  def3: PropTypes.string,
  term4: PropTypes.string,
  def4: PropTypes.string,
};

const mapStateToProps = (state) => ({
  accent: state.color.accent,
});

export default connect(mapStateToProps, null)(Definition);
