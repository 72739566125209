import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Route, Switch, withRouter, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faUser, faLightbulb } from '@fortawesome/free-solid-svg-icons';

import classes from './App.scss';
import MainNavbar from './containers/Navbar';
import Banner from './components/Banner';
import Footer from './components/Footer';
import TitleContent from './components/TitleContent';
import BoxContent from './components/BoxContent';
import Book from './containers/Book';
import Edit from './containers/Edit';
import * as actions from './store/actions/index';

library.add(fab, faUser, faLightbulb);

const MainContent = () => (
  <React.Fragment>
    <Banner accent={classes.accent} />
    <BoxContent />
    <TitleContent />
  </React.Fragment>
);

const BookContent = (props) => <Book {...props} />;

const EditContent = () => (
  <React.Fragment>
    <Edit />
  </React.Fragment>
);

class App extends Component {
  componentDidMount() {
    this.props.tryAutoSignIn();
    this.props.tryColorChange();
  }

  render() {
    return (
      <div className="app text-center m-auto">
        <MainNavbar />
        <Switch>
          <Route path="/book/edit" exact component={EditContent} />
          <Route path="/book/:bookName/:chapter" exact component={BookContent} bookName chapter />
          <Route path="/book/:bookName/" exact component={BookContent} bookName>
            <Redirect to="./1/" />
          </Route>
          <Route path="/" component={MainContent} />
          <Redirect to="/" />
        </Switch>
        <Footer />
      </div>
    );
  }
}

App.propTypes = {
  tryAutoSignIn: PropTypes.func.isRequired,
  tryColorChange: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  tryColorChange: () => dispatch(actions.autoColorChange()),
  tryAutoSignIn: () => dispatch(actions.autoSignIn()),
});

export default withRouter(connect(null, mapDispatchToProps)(App));
