import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from '../store/actions/index';
import { classMap } from '../shared/classMap';

class Book extends Component {
  componentDidMount() {
    this.props.tryChapterChange(this.props.match.params);
  }

  render() {
    const out = [];
    if (this.props.bookName) {
      if (this.props.elements && this.props.elements.length > 0) {
        this.props.elements.forEach((elt, index) => {
          out.push(React.createElement(classMap(elt.type), { ...elt.props, key: index }));
        });
      } else {
        out.push(
          <h3
            className="text-center"
            style={{ margin: '10em 0', color: this.props.accent }}
            key={1}
          >
            This page is under construction. Please check back soon!
          </h3>,
        );
      }
    }

    return <React.Fragment>{out}</React.Fragment>;
  }
}

Book.propTypes = {
  tryChapterChange: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  elements: PropTypes.array,
  accent: PropTypes.string.isRequired,
  bookName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  elements: state.chapter.elements,
  accent: state.color.accent,
  bookName: state.chapter.bookName,
});

const mapDispatchToProps = (dispatch) => ({
  tryChapterChange: (book) => dispatch(actions.changeChapter(book)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Book);
