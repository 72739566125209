import _ from 'lodash';
import { prettifyNumber } from '../../shared/prettifyNumber';

// This is the data for the question
import densityTable from '../../data/densityTable';

const table = densityTable.slice(3);

const DensityEx2 = {
  data: {
    question: 'A gold cube has edges of side length 10 centimeters.  Its mass is _____ kg.',
    material: 'gold',
    mass: 19.3,
    length: 10,
    density: 19300,
    volume: 0.001,
    ans: 19.3,
    ansString: '19.3',
  },

  setQuestion: (difficulty, ansString) => {
    let { question } = DensityEx2.data;
    const {
      length, material,
    } = DensityEx2.data;

    question = `A ${material} cube has edges of side length ${length} centimeters.  Its mass is ${ansString} kg. `;

    DensityEx2.data = { ...DensityEx2.data, question };

    return question;
  },

  getAns: (difficulty) => {
    const { ansString } = DensityEx2.data;

    let { question } = DensityEx2.data;

    question = DensityEx2.setQuestion(difficulty, ansString);

    DensityEx2.data = {
      ...DensityEx2.data,
      question,
    };
    return DensityEx2.data;
  },

  randomize: (difficulty) => {
    let {
      material, mass, length, volume, density, ans, ansString, question,
    } = DensityEx2.data;

    const type = _.sample(table);
    density = type.density;
    material = type.material;
    length = Math.round(Math.random() * 15 + 10);
    volume = (length * length * length) / 1000000;
    mass = density * volume;

    ans = mass;

    ansString = prettifyNumber(ans, 3);

    DensityEx2.data = {
      ...DensityEx2.data,
      ans,
      ansString,
      material,
      mass,
      length,
      density,
      volume,
    };

    question = DensityEx2.setQuestion(difficulty, '_____');

    DensityEx2.data = { ...DensityEx2.data, question };

    return DensityEx2.data;
  },
};

export default DensityEx2;
