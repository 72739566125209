import { connect } from 'react-redux';
import styled from 'styled-components';

import { colorPick } from '../../shared/utility';

const styleDiv = styled.div`
  background-color: ${(props) => (props.bg ? colorPick(props) : 'inherit')};
  color: ${(props) => (!props.bg ? colorPick(props) : 'inherit')};
  fill: ${(props) => (!props.bg ? colorPick(props) : 'inherit')};
`;

const mapStateToProps = (state) => ({
  main: state.color.main,
  accent: state.color.accent,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(styleDiv);
