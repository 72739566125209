import { connect } from 'react-redux';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';

const styleDropdownItem = styled(Dropdown.Item)`
  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.accent};
  }
`;

const mapStateToProps = (state) => ({
  accent: state.color.accent,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(styleDropdownItem);
