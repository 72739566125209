import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

import classes from './Logout.module.scss';
import * as actions from '../../store/actions/index';

const Logout = (props) => (
  <React.Fragment>
    <span className="white">User Logout</span>
    <Dropdown.Divider className={classes.divider} />
    <Button
      className={classes.logout}
      style={{ backgroundColor: props.accent }}
      onClick={() => props.onLogout()}
    >
      <span>Logout</span>
    </Button>
  </React.Fragment>
);

Logout.propTypes = {
  accent: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accent: state.color.accent,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
