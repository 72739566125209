const config = {
    apiKey: "AIzaSyDDUXKE1zOUbHkQf7PxWGARsOmumWrS0jU",
    authDomain: "illuminotes-57d5c.firebaseapp.com",
    databaseURL: "https://illuminotes-57d5c.firebaseio.com",
    projectId: "illuminotes-57d5c",
    storageBucket: "illuminotes-57d5c.appspot.com",
    messagingSenderId: "183688663888",
    appId: "1:183688663888:web:a4d804d60e3636b4d21255"
  };

export default config;
