import React from 'react';
import PropTypes from 'prop-types';

import Markdown from './Markdown';
import * as classes from './List.module.scss';

const List = (props) => {
  const className = classes[props.type];
  return (
    <ul>
      { props.content.map((elt, index) => (
        <li key={ index } className={ `text-left ${className}`}>
          <Markdown source={ elt } />
        </li>
      )) }
    </ul>
  );
};

List.propTypes = {
  content: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default List;
