import PrefixEx from './exercises/PrefixEx';
import NonmetricEx from './exercises/NonmetricEx';
import TimeEx from './exercises/TimeEx';
import AdvancedUnitEx from './exercises/AdvancedUnitEx';
import DensityEx from './exercises/DensityEx';
import DensityEx2 from './exercises/DensityEx2';
import TemperatureEx from './exercises/TemperatureEx';

const ExerciseList = (props) => {
  switch (props.name) {
  case 'PrefixEx':
    return PrefixEx;
  case 'NonmetricEx':
    return NonmetricEx;
  case 'TimeEx':
    return TimeEx;
  case 'AdvancedUnitEx':
    return AdvancedUnitEx;
  case 'DensityEx':
    return DensityEx;
  case 'DensityEx2':
    return DensityEx2;
  case 'TemperatureEx':
    return TemperatureEx;
  default: return {};
  }
};

export default ExerciseList;
