import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './UserNav.module.scss';
import * as actions from '../store/actions/index';

import StyleDropdownMenu from '../components/themed/StyleDropdownMenu';
import StyleDropdownItem from '../components/themed/StyleDropdownItem';
import StyleDropdownToggle from '../components/themed/StyleDropdownToggle';

import SignIn from '../components/Auth/SignIn';
import Logout from '../components/Auth/Logout';

class UserNav extends Component {
  render() {
    const auth = this.props.isLoggedIn ? <Logout /> : <SignIn />;
    return (
      <Nav id={classes.userNav}>
        <Dropdown alignRight>
          <StyleDropdownToggle id={classes.userId}>
            <FontAwesomeIcon icon="user" className="fa-lg" />
          </StyleDropdownToggle>

          <StyleDropdownMenu className="text-center" bg="true" ma="true">
            {auth}
            <Dropdown.Divider className={classes.divider} />
            <span className="white">Customize Color</span>
            <Dropdown.Divider className={classes.divider} />
            <div id="colors" className="d-flex justify-content-around">
              <div className="white">
                Base
                <br />
                <input
                  type="color"
                  id="main"
                  value={this.props.main}
                  onChange={(event) => this.props.onChangeColor({ main: event.target.value })}
                />
              </div>
              <div className="white">
                Accent
                <br />
                <input
                  type="color"
                  id="accent"
                  value={this.props.accent}
                  onChange={(event) => this.props.onChangeColor({ accent: event.target.value })}
                />
              </div>
            </div>
            <StyleDropdownItem className="white" onClick={() => this.props.onRandomColor()}>
              Random Pair
            </StyleDropdownItem>
          </StyleDropdownMenu>
        </Dropdown>
      </Nav>
    );
  }
}

UserNav.propTypes = {
  onChangeColor: PropTypes.func.isRequired,
  onRandomColor: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  main: PropTypes.string.isRequired,
  accent: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  main: state.color.main,
  accent: state.color.accent,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeColor: (colors) => dispatch(actions.changeColor(colors)),
  onRandomColor: () => dispatch(actions.randomColor()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserNav);
