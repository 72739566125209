import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalculator,
  faRedo,
  faAmbulance,
  faArrowDown,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import YouTube from 'react-youtube';

import classes from './Exercise.module.scss';
import Markdown from './Markdown';
import StyleTipButtonNoHalo from './themed/StyleTipButtonNoHalo';
import ExerciseList from './ExerciseList';

const Exercise = (props) => {
  // Video options
  const opts = {
    width: '100%',
    playerVars: {
      autoplay: 1,
      start: props.time || 0,
    },
  };
  // Play video
  const onReady = (event) => {
    event.target.playVideo();
  };
  // Modal show
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // Decide what to show based on video availability
  let vid = <h4 style={{ color: props.accent }}>No video available</h4>;
  if (props.videoId) {
    vid = <YouTube videoId={props.videoId} opts={opts} onReady={onReady} />;
  }

  // Change difficulty
  const [diff, setDiff] = useState(props.difficulty);
  const mapDiff = ['easy', 'med', 'hard'];
  const handleDiff = (add) => {
    if (diff + add > 0 && diff + add < 4) {
      setDiff(diff + add);
    }
  };

  // Change question
  const ex = ExerciseList({ name: props.name });
  let temp;
  if (ex && ex.data) {
    temp = ex.data;
  } else {
    temp = props;
  }
  const [data, setData] = useState(temp);
  const handleRandom = () => {
    if (ex && ex.randomize) {
      setData(ex.randomize(diff));
    }
  };
  const handleAns = () => {
    if (ex && ex.getAns) {
      setData(ex.getAns(diff));
    }
  };

  return (
    <div className={`d-flex w-100 ${classes.exercise}`}>
      <span className={classes.question}>
        <Markdown source={data.question} />
      </span>
      <div className={classes.icons}>
        <StyleTipButtonNoHalo size="sm" className={classes.exButton} acc="true" bg="true">
          <FontAwesomeIcon icon={faCalculator} className="fa-2x white" onClick={handleAns} />
        </StyleTipButtonNoHalo>
        <StyleTipButtonNoHalo size="sm" className={classes.exButton} acc="true" bg="true">
          <FontAwesomeIcon icon={faRedo} className="fa-2x white" onClick={handleRandom} />
        </StyleTipButtonNoHalo>
        <StyleTipButtonNoHalo
          size="sm"
          className={classes.exButton}
          acc="true"
          bg="true"
          onClick={handleShow}
        >
          <FontAwesomeIcon icon={faAmbulance} className="fa-2x white" />
        </StyleTipButtonNoHalo>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
          <Modal.Header closeButton style={{ backgroundColor: props.main }}>
            <Modal.Title className="white">Video Help</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">{vid}</Modal.Body>
          <Modal.Footer style={{ backgroundColor: props.main }}>
            <StyleTipButtonNoHalo onClick={handleClose} acc="true" bg="true">
              <span className="white">Close</span>
            </StyleTipButtonNoHalo>
          </Modal.Footer>
        </Modal>
        <StyleTipButtonNoHalo size="sm" className={classes.exButton} acc="true" bg="true">
          <FontAwesomeIcon
            icon={faArrowDown}
            className="fa-2x white"
            onClick={() => {
              handleDiff(-1);
            }}
          />
          <span className={`${classes.difficulty} white`}>{mapDiff[diff - 1]}</span>
          <FontAwesomeIcon
            icon={faArrowUp}
            className="fa-2x white"
            onClick={() => {
              handleDiff(1);
            }}
          />
        </StyleTipButtonNoHalo>
      </div>
    </div>
  );
};

Exercise.propTypes = {
  accent: PropTypes.string.isRequired,
  main: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  difficulty: PropTypes.number.isRequired,
  time: PropTypes.number,
  videoId: PropTypes.string,
  name: PropTypes.string,
};

const mapStateToProps = (state) => ({
  main: state.color.main,
  accent: state.color.accent,
});

export default connect(mapStateToProps)(Exercise);
