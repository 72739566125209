import { put, call } from 'redux-saga/effects';
import firebase from 'firebase/app';
import 'firebase/auth';

import firebaseApp from '../../config/Firebase';

import * as actions from '../actions/index';

export function* authLogoutSaga() {
  yield call([sessionStorage, 'removeItem'], 'userId');
  yield put(actions.logoutSuccess());
}

export function* authCheckStateSaga() {
  const userId = yield sessionStorage.getItem('userId');
  if (userId) {
    const ref = firebaseApp.database().ref(`users/${userId}`);
    const result = yield call([ref, ref.once], 'value');
    const userData = result.val();
    yield put(actions.authSuccess(
      userId, userData.email, userData.displayName,
    ));
  }
}

export function* authUserSaga(action) {
  // Update store that authentication is starting
  yield put(actions.authStart(action.provider));

  try {
    // Get the correct authentication provider
    let firebaseProvider = null;
    if (action.provider === 'Google') {
      firebaseProvider = new firebase.auth.GoogleAuthProvider();
    } else {
      firebaseProvider = new firebase.auth.FacebookAuthProvider();
    }
    // Use the provider to authenticate the user
    const auth = firebaseApp.auth();
    const authResult = yield call([auth, auth.signInWithPopup], firebaseProvider);

    // Finalize the authentication by storing the user id
    // and passing user data to the reducer to update the store
    yield sessionStorage.setItem('userId', authResult.user.uid);
    yield put(actions.authSuccess(
      authResult.user.uid, authResult.user.email, authResult.user.displayName,
    ));

    // Fetch the user's data from the Firebase database
    const ref = firebaseApp.database().ref(`users/${authResult.user.uid}`);
    const result = yield call([ref, ref.once], 'value');
    const userData = result.val();

    // Check to see if this is the first login
    if (!userData || !userData.firstLogin) {
      yield call([ref, ref.update],
        {
          firstLogin: Date(),
          email: authResult.user.email,
          provider: action.provider,
        });
    }

    // Update the user's latest login
    yield call([ref, ref.update], { lastLogin: Date(), displayName: authResult.user.displayName });

    // Update the number of page views
    let views = userData.pageViews;
    if (views) {
      views += 1;
    } else {
      views = 1;
    }
    yield call([ref, ref.update], { pageViews: views });

    // Change color to match the user's stored preference
    if (userData.mainColor && userData.accentColor) {
      const colors = { main: userData.mainColor, accent: userData.accentColor };
      yield put(actions.changeColor(colors));
    }
  } catch (error) {
    yield put(actions.authFail(error));
  }
}

export default authUserSaga;
