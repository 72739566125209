import React, { Component } from 'react';
import {
  Route, Switch, withRouter, Redirect, Link,
} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

import StyleNavbar from '../components/themed/StyleNavbar';
import Logo from '../components/Logo/logo';
import MainMenuOptions from '../components/MainMenuOptions';
import UserNav from './UserNav';
import TOC from './TOC';

import classes from './Navbar.module.scss';

const fullDiv = () => (
  <React.Fragment>
    <Navbar.Brand id={classes.navbarBrand}>
      <Link to="/" style={{ textDecoration: 'none' }}><Logo /></Link>
    </Navbar.Brand>
    <div className="w-100"/>
    <TOC/>
  </React.Fragment>
);

const menu = () => (
  <React.Fragment>
    <Navbar.Brand id={classes.navbarBrand} className="d-none d-sm-block">
      <Link to="/" style={{ textDecoration: 'none' }}><Logo /></Link>
    </Navbar.Brand>
    <MainMenuOptions/>
  </React.Fragment>
);

class MainNavbar extends Component {
  render() {
    return (
      <StyleNavbar ma="true" bg="true">
        <Switch>
          <Route path="/book" component={ fullDiv } />
          <Route path="/" exact component={ menu } />
          <Redirect to="/" />
        </Switch>
        <UserNav />
      </StyleNavbar>
    );
  }
}

export default withRouter(MainNavbar);
