import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TitleContent = (props) => (
  <Container fluid="true">
    <Row>
      <Col xs="12" xl="6">
        <a name="Backed">
          <h4 style={{ color: props.accent }}>Backed by Research</h4>
        </a>
        <br />
        <p className="text-justify gray">
          With illuminotes, you can focus on learning in the way that is most natural to you. If you
          learn best by reading and studying the material, then you can do that. If you learn best
          by working lots of problems, there are an unlimited number of problems for you to choose
          from. The best news is, research has shown that choosing your own type of learning leads
          to increased success!
        </p>
      </Col>

      <Col xs="12" className="d-xl-none">
        <hr />
      </Col>

      <Col xs="12" xl="6">
        <a name="About">
          <h4 style={{ color: props.accent }}>About Us</h4>
        </a>
        <br />
        <p className="text-justify gray">
          We believe the best learning happens when students get the resources they need. We&apos;ve
          taught in classrooms at both the high school and college level. We know how important it
          is to focus on students first. That&apos;s why we are committed to bringing you the very
          best material in a form that helps you learn efficiently.
        </p>
      </Col>
    </Row>
  </Container>
);

TitleContent.propTypes = {
  accent: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  accent: state.color.accent,
});

export default connect(mapStateToProps)(TitleContent);
