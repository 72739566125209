import { prettifyNumber } from '../../shared/prettifyNumber';

// This is the data for the question

const TemperatureEx = {
  data: {
    question: '100 ºF = ____ ºC',
    fahrenheit: 100,
    celsius: 37.8,
    kelvin: 311,
    dir: 2,
    ans: 37.8,
    ansString: '37.8',
  },

  setQuestion: (difficulty, ansString) => {
    let { question } = TemperatureEx.data;
    const {
      celsius, fahrenheit, kelvin, dir,
    } = TemperatureEx.data;

    if (difficulty === 1) {
      if (dir === 1) {
        question = `${celsius} ºC = ${ansString} K `;
      } else {
        question = `${kelvin} K = ${ansString} ºC `;
      }
    }
    if (difficulty === 2) {
      if (dir === 1) {
        question = `${celsius} ºC = ${ansString} ºF `;
      } else {
        question = `${fahrenheit} ºF = ${ansString} ºC `;
      }
    }
    if (difficulty === 3) {
      if (dir === 1) {
        question = `${fahrenheit} ºF = ${ansString} K `;
      } else {
        question = `${kelvin} K = ${ansString} ºF `;
      }
    }
    TemperatureEx.data = { ...TemperatureEx.data, question };

    return question;
  },

  getAns: (difficulty) => {
    const { ansString } = TemperatureEx.data;

    let { question } = TemperatureEx.data;

    question = TemperatureEx.setQuestion(difficulty, ansString);

    TemperatureEx.data = {
      ...TemperatureEx.data,
      question,
    };
    return TemperatureEx.data;
  },

  randomize: (difficulty) => {
    let {
      fahrenheit, celsius, kelvin, dir, ans, ansString, question,
    } = TemperatureEx.data;

    kelvin = Math.round(Math.random() * 150 + 290);
    dir = Math.round(Math.random() * 1 + 1);
    celsius = kelvin - 273;
    fahrenheit = Math.round(1.8 * celsius + 32);

    if (difficulty === 1) {
      if (dir === 1) {
        ans = kelvin;
      } else {
        ans = celsius;
      }
    }
    if (difficulty === 2) {
      if (dir === 1) {
        ans = fahrenheit;
      } else {
        ans = celsius;
      }
    }
    if (difficulty === 3) {
      if (dir === 1) {
        ans = kelvin;
      } else {
        ans = fahrenheit;
      }
    }

    ansString = prettifyNumber(ans, 3);

    TemperatureEx.data = {
      ...TemperatureEx.data,
      ans,
      ansString,
      fahrenheit,
      kelvin,
      celsius,
      dir,
    };

    question = TemperatureEx.setQuestion(difficulty, '_____');

    TemperatureEx.data = { ...TemperatureEx.data, question };

    return TemperatureEx.data;
  },
};

export default TemperatureEx;
