import React from 'react';
import MathJax from 'react-mathjax2';
import ReactMarkdown from 'react-markdown';
import RemarkMathPlugin from 'remark-math';
import RemarkSubSuper from 'remark-sub-super';
import PropTypes from 'prop-types';

const Markdown = (props) => {
  const newProps = {
    ...props,
    plugins: [RemarkMathPlugin, RemarkSubSuper],
    renderers: {
      ...props.renderers,
      paragraph: 'span',
      sub: 'sub',
      sup: 'sup',
      math: function innerMath(props2) {
        return <MathJax.Node>{ props2.value }</MathJax.Node>;
      },
      inlineMath: function innerInlineMath(props2) {
        return <MathJax.Node inline>{ props2.value }</MathJax.Node>;
      },
    },
  };

  return (
    <MathJax.Context input="tex">
      <ReactMarkdown {...newProps} />
    </MathJax.Context>
  );
};

Markdown.propTypes = {
  value: PropTypes.string,
  renderers: PropTypes.object,
};

export default Markdown;
