import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

const PrefixTable = React.lazy(() => import('./tables/PrefixTable'));
const BaseUnitTable = React.lazy(() => import('./tables/BaseUnitTable'));
const NonMetricTable = React.lazy(() => import('./tables/NonMetricTable'));
const TimeTable = React.lazy(() => import('./tables/TimeTable'));
const AccelerationTable = React.lazy(() => import('./tables/AccelerationTable'));
const ForceTable = React.lazy(() => import('./tables/ForceTable'));
const CoefficientFrictionTable = React.lazy(() => import('./tables/CoefficientFrictionTable'));
const DensityTable = React.lazy(() => import('./tables/DensityTable'));
const MeltingPointTable = React.lazy(() => import('./tables/MeltingPointTable'));
const SpecificHeatTable = React.lazy(() => import('./tables/SpecificHeatTable'));
const LatentHeatTable = React.lazy(() => import('./tables/LatentHeatTable'));
const ThermalExpansionTable = React.lazy(() => import('./tables/ThermalExpansionTable'));
const RotationalInertiaTable = React.lazy(() => import('./tables/RotationalInertiaTable'));

const OtherComponent = (props) => {
  switch (props.name) {
  case 'PrefixTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <PrefixTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'BaseUnitTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <BaseUnitTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'NonMetricTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <NonMetricTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'TimeTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <TimeTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'AccelerationTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <AccelerationTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'ForceTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <ForceTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'CoefficientFrictionTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <CoefficientFrictionTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'DensityTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <DensityTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'MeltingPointTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <MeltingPointTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'SpecificHeatTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <SpecificHeatTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'LatentHeatTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <LatentHeatTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'ThermalExpansionTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <ThermalExpansionTable/>
        </Suspense>
      </React.Fragment>
    );
  case 'RotationalInertiaTable':
    return (
      <React.Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <RotationalInertiaTable/>
        </Suspense>
      </React.Fragment>
    );
  default: return (
    <React.Fragment>
    </React.Fragment>
  );
  }
};

OtherComponent.propTypes = {
  name: PropTypes.string.isRequired,
};

export default OtherComponent;
