import _ from 'lodash';
import pluralize from 'pluralize';

import { prettifyNumber } from '../../shared/prettifyNumber';
import nonMetricUnits from '../../data/nonMetricTable';
import innerJoin from '../../shared/join';
import prefixes from '../../data/prefixTable';
import time from '../../data/timeTable';

// This is the data for the question
const fullTable = [];
for (let i = 0; i < 3; i += 1) {
  fullTable.push(
    innerJoin(nonMetricUnits[i].nonMetricConversions, prefixes, 'metricPrefix', 'prefix'),
  );
}

const AdvancedUnitEx = {
  data: {
    question: '1 kilometer/second = ____ meters/second',
    typeVal: 0,
    type: fullTable[0],
    unitVal1n: 0,
    unitVal1d: 0,
    unitVal2n: 1,
    unitVal2d: 0,
    val: 1,
    valString: '1',
    dir: 1,
    ans: 1000,
    ansString: '1,000',
  },

  setQuestion: (difficulty, ansString) => {
    const {
      ans, val, valString, unitVal1n, unitVal1d, unitVal2n, unitVal2d, type, typeVal, dir,
    } = AdvancedUnitEx.data;

    let { question } = AdvancedUnitEx.data;

    if (difficulty === 1 || difficulty === 3) {
      if (dir === 0) {
        question = `${valString} ${pluralize(type[unitVal1n].nonMetric, val)}/${pluralize(time[unitVal1d].unit, 1)} = ${ansString} ${type[unitVal2n].metricPrefix}${pluralize(nonMetricUnits[typeVal].metricUnit, ans)}/${pluralize(time[unitVal2d].unit, 1)}`;
      } else {
        question = `${valString} ${type[unitVal1n].metricPrefix}${pluralize(nonMetricUnits[typeVal].metricUnit, val)}/${pluralize(time[unitVal1d].unit, 1)} = ${ansString} ${pluralize(type[unitVal2n].nonMetric, ans)}/${pluralize(time[unitVal2d].unit, 1)}`;
      }
    }

    if (difficulty === 2) {
      if (dir === 0) {
        question = `${valString} ${pluralize(type[unitVal1n].nonMetric, val)}/${pluralize(time[unitVal1d].unit, 1)} = ${ansString} ${pluralize(type[unitVal1n].nonMetric, ans)}/${pluralize(time[unitVal2d].unit, 1)}`;
      } else {
        question = `${valString} ${type[unitVal1n].metricPrefix}${pluralize(nonMetricUnits[typeVal].metricUnit, val)}/${pluralize(time[unitVal1d].unit, 1)} = ${ansString} ${type[unitVal2n].metricPrefix}${pluralize(nonMetricUnits[typeVal].metricUnit, ans)}/${pluralize(time[unitVal2d].unit, 1)}`;
      }
    }

    AdvancedUnitEx.data = {
      ...AdvancedUnitEx.data, question,
    };

    return question;
  },

  getAns: (difficulty) => {
    const { ansString } = AdvancedUnitEx.data;

    let { question } = AdvancedUnitEx.data;

    question = AdvancedUnitEx.setQuestion(difficulty, ansString);

    AdvancedUnitEx.data = {
      ...AdvancedUnitEx.data, question,
    };
    return AdvancedUnitEx.data;
  },

  randomize: (difficulty) => {
    let {
      ans, ansString, val, valString, unitVal1n, unitVal1d,
      unitVal2n, unitVal2d, type, typeVal, dir, question,
    } = AdvancedUnitEx.data;

    typeVal = _.random(2);
    type = fullTable[typeVal];
    unitVal1n = _.random(type.length - 1);
    unitVal2n = unitVal1n;
    unitVal1d = _.random(time.length - 1);
    unitVal2d = unitVal1d;
    val = _.random(1, 100) * 10;
    valString = prettifyNumber(val);
    dir = _.random(1);

    // Make the time units different
    if (difficulty === 2 || difficulty === 3) {
      while (Math.abs(unitVal1d - unitVal2d) !== 1) {
        unitVal1d = _.random(time.length - 1);
        unitVal2d = _.random(time.length - 1);
      }
    }


    if (difficulty === 1 || difficulty === 3) {
      if (dir === 0) {
        ans = ((val * time[unitVal2d].scale) / time[unitVal1d].scale) * type[unitVal1n].convert;
      } else {
        ans = (val * time[unitVal2d].scale) / time[unitVal1d].scale / type[unitVal1n].convert;
      }
    }
    if (difficulty === 2) {
      ans = (val * time[unitVal2d].scale) / time[unitVal1d].scale;
    }

    ansString = prettifyNumber(ans, 3);


    AdvancedUnitEx.data = {
      ...AdvancedUnitEx.data,
      ans,
      val,
      valString,
      unitVal1n,
      unitVal1d,
      unitVal2n,
      unitVal2d,
      type,
      typeVal,
      dir,
      ansString,
    };

    question = AdvancedUnitEx.setQuestion(difficulty, '_____');

    AdvancedUnitEx.data = { ...AdvancedUnitEx.data, question };


    return AdvancedUnitEx.data;
  },
};

export default AdvancedUnitEx;
