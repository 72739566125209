import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  main: '#0074D9',
  accent: '#FFDC00',
};

const changeColorSuccess = (state, action) => updateObject(state, action.colors);

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.CHANGE_COLOR_SUCCESS: return changeColorSuccess(state, action);
  default:
    return state;
  }
};

export default reducer;
